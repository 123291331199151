import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'popper.js/dist/umd/popper.min.js';
import 'jquery/dist/jquery.min.js';
import BookYOurAurmSafe from './components/BookYourAurmSafe';

function App() {
  return (
    <div>
      <BookYOurAurmSafe />
    </div>
  );
}

export default App;
